import { authenticateUser, userIsAuthenticated } from '@/composables/user'
import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/dienstleistungen',
    name: 'dienstleistungen',
    component: () => import('../views/DienstleistungenView.vue')
  },
  {
    path: '/ueber-uns',
    name: 'ueber-uns',
    component: () => import('../views/UeberUnsView.vue')
  },
  {
    path: '/kontakt',
    name: 'kontakt',
    component: () => import('../views/KontaktView.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/LoginView.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/RegisterView.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/LoginView.vue'),
  },
  {
    path: '/recover',
    name: 'recover',
    component: () => import('../views/RecoverView.vue'),
  },
  {
    path: '/shop',
    name: 'shop',
    component: () => import('../views/ShopView.vue'),
    beforeEnter: () => {
      if (localStorage.userData) {
        authenticateUser(JSON.parse(localStorage.userData).jwt, 'shop')
      }
    }
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('../views/DashboardView.vue'),
    beforeEnter: () => {
      if (localStorage.userData) {
        authenticateUser(JSON.parse(localStorage.userData).jwt, 'dashboard')
      }
      else {
        router.push('login')
      }
    }
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('../views/ProfileView.vue'),
    beforeEnter: () => {
      if (localStorage.userData) {
        authenticateUser(JSON.parse(localStorage.userData).jwt, 'profile')
      }
      else {
        router.push('login')
      }
    }
  },
  {
    path: '/checkout',
    name: 'checkout',
    component: () => import('../views/CheckoutView.vue'),
    beforeEnter: () => {
      if (localStorage.userData) {
        authenticateUser(JSON.parse(localStorage.userData).jwt, 'checkout')
      }
      else if (!userIsAuthenticated.value) {
        router.push('login')
      }
    }
  },
  {
    path: '/agb',
    name: 'agb',
    component: () => import('../views/AgbView.vue')
  },
  {
    path: '/impressum',
    name: 'impressum',
    component: () => import('../views/ImpressumView.vue')
  },
  {
    path: '/datenschutz',
    name: 'datenschutz',
    component: () => import('../views/DatenschutzView.vue')
  },
  {
    path: '/not-found',
    name: 'not-found',
    component: () => import('../views/NotFoundView.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/not-found'
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior() {
    return { top: 0 }
  },
  routes
})

export default router
