<template>
<div class="footer">
    <img src="@/assets/img/global/footer-bg.png" alt="Clouds" class="cloud-img">
    <div class="col-4-flex">
        <img src="@/assets/img/global/logo.png" alt="Logo">
        <div class="quicklinks">
            <router-link to="/">Startseite</router-link>
            <router-link to="/dienstleistungen">Dienstleistungen</router-link>
            <router-link to="/ueber-uns">Über uns</router-link>
            <router-link to="/kontakt">Kontakt</router-link>
        </div>
        <div class="dsgvo">
            <router-link to="/impressum">Impressum</router-link>
            <router-link to="/agb">AGB</router-link>
            <router-link to="/datenschutz">Datenschutz</router-link>
        </div>
        <div class="appstore">
            <img src="@/assets/img/global/downloadApple.png" alt="Appstore">
            <img src="@/assets/img/global/downloadGoogle.png" alt="Playstore">
        </div>
    </div>
</div>
</template>

<script setup>

</script>

<style lang="scss">
    .cloud-img {
        display: block;
    }

    .footer {
        overflow-x: hidden;
        .col-4-flex {
            display: flex;
            justify-content: space-around;
            padding: 1em var(--side-padding);
            background-color: var(--light);
            flex-wrap: wrap;
            gap: 1em 2em;
        }

        .quicklinks, .dsgvo, .appstore {
            display: flex;
            flex-direction: column;
        }

        .appstore {
            gap: 1em;
            img {
                height: 50px;
            }
        }

        a {
            text-decoration: none;
            color: var(--pink);
        }
    }


</style>