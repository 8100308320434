<template>
<header class="header">
    <div class="col-2-flex">
    <img src="@/assets/img/home/handyBanner.png" alt="Handy Banner">
    <div class="text">
        <h1>Ihre Zufriedenheit ist unsere Priorität</h1>
        <p>SKY Textilpflege bietet den besten Service in und um Hamburg an. Faire Preise, hochwertige Qualität und hervorragender Kundenservice. Dafür stehen wir!</p>
        <router-link to="/shop" class="cta">Jetzt bestellen</router-link>
    </div>
    </div>
</header>
</template>

<style lang="scss">
.header {
  margin-top: 80px;
  background-image: url(@/assets/img/home/header-bg.png);
  background-repeat: no-repeat;
  background-position: top right;
  background-size: auto 100%;
  min-height: 955px;
  padding: 2rem var(--side-padding);
  padding-top: 100px;
  .col-2-flex {
    display: flex;
    flex-wrap: wrap;
    gap: 2em;
    justify-content: center;
    align-items: center;
    margin-top: 5em;
  }
  img {
    width: clamp(300px, 900px, 80%);
    max-width: 950px;
    flex: 3 1 600px;
  }
  .text {
    flex: 1 1 320px;
    max-width: 800px;
    background-color: var(--blue);
    border-radius: 2em;
    box-sizing: border-box;
    padding: 2em;
    color: white;
    display: flex;
    flex-direction: column;
    gap: 2em;
    align-items: flex-start;
    box-shadow: var(--shadow-wide-light);
    h1 {
      color: white;
    }
  }
}
</style>