<template>
<section class="services">
    <h2>Bereitstellen</h2>
    <h1>Besondere Dienstleistungen</h1>
    <div class="col-4-flex">
        <div class="col-2-flex">
            <div class="card">
                <img src="@/assets/img/home/servicesTshirt.png" alt="T-Shirt">
                <h1>Reinigung Service</h1>
                <p>Auf SKY Textilpflege können Sie jederzeit zählen, wenn es um Ihre Belange geht! Erfahren Sie unten mehr über die Leistungen, die wir Ihnen anbieten und melden Sie sich gerne mit spezifischen Fragen bei uns.</p>
            </div>
            <div class="card">
                <img src="@/assets/img/home/servicesSchuhe.png" alt="Schuhe">
                <h1>Schuh Service</h1>
                <p>Gerne bieten wir unseren Kunden hervorragenden Service an. Unsere Kunden arbeiten sehr gerne mit uns und greifen auch erneut auf unseren Service zurück. Melden Sie sich bei uns, wir helfen Ihnen gerne!</p>
            </div>
        </div>
        <div class="col-2-flex">
            <div class="card">
                <img src="@/assets/img/home/servicesHemd.png" alt="Hemd">
                <h1>Bügel Service</h1>
                <p>Ganz gleich, welchen Service Sie suchen, bei uns finden Sie das Richtige! Unser Team bietet ihnen auch einen professionellen Bügelservice an.</p>
            </div>
            <div class="card">
                <img src="@/assets/img/home/servicesWaschmaschine.png" alt="Wäsche">
                <h1>Wäsche Service</h1>
                <p>Unser hochwertiger Wäscheservice steht ihnen mit einer großen Auswahl zur Verfügung. Werfen Sie unten einen Blick darauf, was unser Spezialgebiet ist und melden Sie sich gerne mit zusätzlichen Fragen direkt bei uns.</p>
            </div>
        </div>
    </div>
    <router-link to="/dienstleistungen" class="cta">Alle Dienste</router-link>
</section>
</template>

<style lang="scss">
.services {
    padding: 8rem var(--side-padding);
    background-color: var(--light);
    text-align: center;
    margin-bottom: 4rem;
    h2 {
        color: var(--pink);
    }
    h1 {
        margin-bottom: 2rem;
    }
    .cta {
        margin-top: 3rem;
    }
    .col-4-flex {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-content: stretch;
        gap: 2rem;
    }
    .col-2-flex {
        flex: 1 1 750px;
        display: flex;
        flex-wrap: wrap;
        gap: 2rem;
        text-align: center;

    }
    .card {
        flex: 1 1 288px;
        background-color: white;
        border-radius: 2rem;
        padding: 2rem;
        h1 {
            color: var(--pink);
            font-size: 2rem;
            margin: 1rem 0;
        }
        p {
            font-size: 1.2rem;
            color: var(--medium);
        }
    }
}
</style>