<template>
<section class="why-sky">
    <div class="col-2-flex">
        <img src="@/assets/img/home/whySkyOnlyMan.png" alt="Buisnessman">
        <div class="text">
            <h2>Gründe dafür</h2>
            <h1>Warum uns wählen?</h1>
            <p>Wir tun alles, damit unsere Kunden die besten individuellen Optionen erhalten, darunter auch spezielle Services, die ihnen zur Verfügung stehen. Lesen Sie nachfolgend mehr darüber und melden Sie sich gerne mit Fragen bei uns.</p>
        </div>
    </div>
</section>
</template>

<style lang="scss">
.why-sky {
    position: relative;
    background-image: url(@/assets/img/home/whySkyBg.png);
    //background-size: auto 100%;
    background-size: 100% 100%;
    //background-position: center;
    background-repeat: no-repeat;
    padding: 12rem var(--side-padding);
    margin-bottom: 4rem;
    .col-2-flex {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        align-items: center;
        gap: 2rem 8rem;
    }
    img {
        max-width: min(600px, 100%);
        flex: 0 1 600px;
    }
    .text {
        flex: 1 1 400px;
        color: white;
        max-width: 800px;
        h1 {
            color: white;
            margin-bottom: 1rem;
        }
    }
}
@media (max-width: 1600px) {
    .why-sky {
        background-size: auto 100%;
    }
}
</style>