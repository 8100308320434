import router from '@/router'
import { ref } from 'vue'

export const userData = ref({
    jwt: '',
})

export const userIsAuthenticated = ref(false)

export const authenticateUser = async (jwt, route) => {
    const settings = {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'jwt': jwt
        },
    }
    const response = await fetch(`${process.env.VUE_APP_API_ROOT}web/customer/`, settings)
    const result = await response.json()
    if (response.status === 200) {
        userIsAuthenticated.value = true
        setUserData(result, jwt)
        localStorage.userData = JSON.stringify(userData.value)
        router.push(route)
    }
}

export const logoutUser = () => {
    localStorage.removeItem('userData')
    userIsAuthenticated.value = false
    router.push('login')
}

const setUserData = (data, jwt) => {
    userData.value.firstname = data.firstname
    userData.value.lastname = data.lastname
    userData.value.company = data.company
    userData.value.company2 = data.company2
    userData.value.email = data.emails[0].input
    userData.value.emails = data.emails
    userData.value.customerId = data.customerid
    userData.value.jwt = jwt
}

export const updateUserData = async () => {
    const settings = {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'jwt': userData.value.jwt
        },
        body: JSON.stringify(userData.value)
    }
    await fetch(`${process.env.VUE_APP_API_ROOT}web/customer`, settings)
    //const response = await fetch(`${process.env.VUE_APP_API_ROOT}web/customer`, settings)
    //const data = await response.json()
    //console.log(data)
}