<template>
<section class="work-steps">
    <h2>Schritte</h2>
    <h1>Wie Wir Arbeiten</h1>
    <div class="col-2-flex">
        <div class="text">
            <h2>Schritt: 1</h2>
            <h1>Du Bestellst</h1>
            <p>Lade dir die App herunter oder besuche uns auf unserer Website egal wo du gerade bist. Du kannst deine Bestellung einfach und bequem aufgeben und um den Rest kümmern wir uns.</p>
            <router-link to="/shop" class="cta">Jetzt Bestellen</router-link>
        </div>
        <img src="@/assets/img/home/steps1Handy.png" alt="Smartphone">
    </div>
    <div class="col-2-flex">
        <img src="@/assets/img/home/steps2Frau.png" alt="Frau">
        <div class="text">
            <h2>Schritt: 2</h2>
            <h1>Wir Holen</h1>
            <p>Deine Bestellung wird von unserem Lieferfahrer zu deinen Wunschzeiten abgeholt. Genieße den Service, unser Fahrer gibt dir 10 Min. vor Ankunft per SMS Bescheid, dass er gleich bei dir ist. So hast du noch Zeit, dich auf seine Ankunft vorzubereiten.</p>
            <router-link to="/shop" class="cta">Jetzt Bestellen</router-link>
        </div>
    </div>
    <div class="col-2-flex">
        <div class="text">
            <h2>Schritt: 3</h2>
            <h1>Wir Liefern</h1>
            <p>Wir liefern dir deinen Auftrag nach der Bearbeitungszeit wieder und auch hier erhältst du vor Ankunft des Fahrers eine SMS, dass er, auf dem Weg zu dir ist. Alle Artikel werden nach einer Bearbeitungszeit von 48 Stunden geliefert.</p>
        </div>
        <img src="@/assets/img/home/steps3Lieferjunge.png" alt="Lieferjunge">
    </div>
</section>
</template>

<style lang="scss">
.work-steps {
    padding: 1rem var(--side-padding);
    text-align: center;
    color: var(--dark);
    margin-bottom: 4rem;
    h2 {
        color: var(--pink);
    }
    h1 {
        margin-bottom: 2rem;
    }
    .col-2-flex {
        display: flex;
        flex-wrap: wrap-reverse;
        justify-content: space-around;
        align-items: center;
        gap: 2rem 4rem;
        margin-bottom: 4rem;
        &:nth-of-type(2) {
            flex-wrap: wrap;
        }
    }
    .text {
        flex: 1 1 400px;
        max-width: 800px;
        text-align: left;
        h1, p {
            margin-bottom: 1rem;
        }
    }
    img {
        flex: 0 1 650px;
        width: 100%;
        max-width: 100%;
    }
}
</style>