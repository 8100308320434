<template>
<section class="faq">
    
    <div class="col-2-flex">
        
        <div class="left">
            <img src="@/assets/img/home/faqOnlyWomen.png" alt="Frau am Tresen">
        </div>

        <div class="right">
            <h2>FAQ's</h2>
            <h1>Häufig Gestellte Fragen</h1>
            <div class="accordion">
                <div class="accordion-header">
                    <p>Welche Art von Kundenkonto kann man erstellen?</p>
                    <div class="chevron">
                        <img src="@/assets/img/plus-icon.svg" alt="Plus">
                    </div>
                </div>
                <div class="accordion-content-wrap" style="max-height: 0px;">
                    <div class="accordion-content">
                        <p>Privat Accounts & Geschäftskunden Accounts</p>
                    </div>
                </div>
            </div>
        
            <div class="accordion">
                <div class="accordion-header">
                    <p>Welche Lieferzeiten haben wir?</p>
                    <div class="chevron">
                        <img src="@/assets/img/plus-icon.svg" alt="Plus">
                    </div>
                </div>
                <div class="accordion-content-wrap" style="max-height: 0px;">
                    <div class="accordion-content">
                        <p>Lieferungen werden ab einer Bearbeitungszeit von 48 Stunden ausgeführt</p>
                    </div>
                </div>
            </div>
        
            <div class="accordion">
                <div class="accordion-header">
                    <p>Was tue ich wenn ich nicht im Liefergebiet wohne/arbeite?</p>
                    <div class="chevron">
                        <img src="@/assets/img/plus-icon.svg" alt="Plus">
                    </div>
                </div>
                <div class="accordion-content-wrap" style="max-height: 0px;">
                    <div class="accordion-content">
                        <p>In dem Fall kannst du dich mit uns in Verbindung setzen und wir finden eine Lösung.</p>
                    </div>
                </div>
            </div>
        
            <div class="accordion">
                <div class="accordion-header">
                    <p>Was ist der Unterschied zwischen Wäschekorb und Einkaufswagen?</p>
                    <div class="chevron">
                        <img src="@/assets/img/plus-icon.svg" alt="Plus">
                    </div>
                </div>
                <div class="accordion-content-wrap" style="max-height: 0px;">
                    <div class="accordion-content">
                        <p>In den Wäschekorb kommen Artikel aus der Warengruppe Reinigung & Wäscherei und der Einkaufskorb steht für alle anderen Warengruppen, die wir auf unserer Website anbieten.</p>
                    </div>
                </div>
            </div>
        
            <div class="accordion">
                <div class="accordion-header">
                    <p>Kann ich auch per Post zuschicken?</p>
                    <div class="chevron">
                        <img src="@/assets/img/plus-icon.svg" alt="Plus">
                    </div>
                </div>
                <div class="accordion-content-wrap" style="max-height: 0px;">
                    <div class="accordion-content">
                        <p>Du kannst uns auch deinen Auftrag per Post zukommen lassen, wir werden diesen für dich nach Absprache bearbeiten und per Post an dich zurückschicken.</p>
                    </div>
                </div>

        </div>
    </div>
    </div>

</section>
</template>

<script setup>
import { onMounted } from 'vue';


onMounted(() => {

    const accordion_header = document.querySelectorAll('.accordion-header');

    accordion_header.forEach(header => {
        header.addEventListener('click', (e) => {
            accordion_header.forEach(item => {
                item.nextElementSibling.style.maxHeight = 0;
            })
            if (e.target.nextElementSibling.style.maxHeight === '0px') {
                e.target.nextElementSibling.style.maxHeight = e.target.nextElementSibling.firstElementChild.offsetHeight + 'px';
            }
            else {
                e.target.nextElementSibling.style.maxHeight = 0;
            }
        })
    })
})

</script>

<style lang="scss">
.faq {
    padding: 1rem var(--side-padding) 1rem 0;
    text-align: center;
    h2 {
        color: var(--pink);
    }
    h1 {
        margin-bottom: 2rem;
    }
    .col-2-flex {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        gap: 4rem;
    }
    .left {
        background-image: url(@/assets/img/home/wolkenVonLinks.png);
        background-repeat: no-repeat;
        background-position: right;
        flex: 1 1 600px;
        img {
            max-width: 100%;
        }
    }
    .right {
        padding-left: var(--side-padding);
        flex: 1 1 400px;
        max-width: 800px;
    }
}
.accordion {
    margin-bottom: 5px;
    box-shadow: var(--shadow);
    border-radius: 2rem;
    margin-bottom: 1rem;
    .accordion-header {
        padding: 1rem;
        border-radius: 2rem;
        user-select: none;
        display: flex;
        justify-content: space-between;
        text-align: left;
        align-items: center;
        cursor: pointer;
        p {
            pointer-events: none;
            margin: 0;
            color: var(--dark);
            font-size: 1.1rem;
        }
        .chevron {
            pointer-events: none;
            transition: rotate 250ms ease-out;
            color: var(--dark);
            img {
                height: 2rem;
            }
            &:hover {
                color: white;
            }
        }
    }
    .accordion-content-wrap {
        overflow: hidden;
        transition: max-height 125ms ease-out;
    }
    .accordion-content {
        p {
            text-align: left;
            padding: 1rem;
            color: var(--pink);
            font-size: 1.1rem;
        }
    }
}
</style>