<template>
<section class="welcome-teaser">
<div class="col-2-flex">
    <div class="video-wrap">
        <video controls class="video" poster="@/assets/img/home/handyBanner.png" preload="none">
            <source src="@/assets/media/teaser.mp4" type="video/mp4">
            Your browser does not support the video tag.
        </video>
    </div>
    <div class="text">
        <h2>Herzlich willkommen</h2>
        <h1>Wie Wir Arbeiten</h1>
        <p>Kurz und Knapp!</p>
        <p>Du hast mal wieder keine Zeit und brauchst professionelle Hilfe? Wir von SKY Textilpflege unterstützen dich, wo wir nur können! Bei uns kannst du dich zurücklehnen und entspannen oder dich um Wichtigeres kümmern als lästige Wäsche.</p>
        <router-link to="/urber-uns" class="cta">Über uns</router-link>
    </div>
</div>
</section>
</template>

<style lang="scss">
.welcome-teaser {
    padding: 2rem var(--side-padding);
    margin-bottom: 4rem;
    .col-2-flex {
        display: flex;
        flex-wrap: wrap;
        gap: 4rem;
        justify-content: center;
        align-items: center;
    }
    .video-wrap {
        border-radius: 2em;
        overflow: hidden;
        position: relative;
        flex: 0 1 800px;
        //background-color: var(--pink);
        //border: 2px solid var(--pink);
    }
    .video {
        position: relative;
        z-index: 0;
        left: 0;
        top: 0;
        opacity: 1;
        max-width: 100%;
    }
    .video[poster]{
        object-fit: scale-down;
    }
    .text {
        flex: 1 1 600px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        color: var(--dark);
        max-width: 800px;
        h1, h2 {
            text-align: center;
            width: 100%;
        }
        h2 {
            color: var(--pink);
        }
        h1, p {
            margin-bottom: 1rem;
        }
    }
}
</style>